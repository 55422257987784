<template>
  <section class="full-content" id="cloudScene">
    <div class="sun">
      <svg height="100" viewBox="0 0 800 800" width="100">
        <path
            d="M716.712 400L794.561 300.166C799.882 293.344 801.406 284.482 798.652 276.393C795.897 268.304 789.206 261.991 780.698 259.454L656.225 222.353L657.712 97.9201C657.815 89.4149 653.589 81.3907 646.379 76.3912C639.168 71.3916 629.864 70.0359 621.421 72.7578L497.868 112.56L422.428 11.0592C417.272 4.1214 408.912 0 400 0C391.087 0 382.728 4.1214 377.571 11.0592L302.129 112.562L178.574 72.7596C170.129 70.0394 160.826 71.3933 153.616 76.3929C146.406 81.3925 142.181 89.4166 142.283 97.9218L143.772 222.355L19.2988 259.456C10.7911 261.992 4.10011 268.306 1.34671 276.395C-1.40852 284.484 0.116665 293.344 5.43832 300.168L83.287 400.002L5.43832 499.836C0.116665 506.658 -1.40669 515.52 1.34671 523.609C4.10011 531.698 10.793 538.011 19.2988 540.548L143.772 577.647L142.283 702.08C142.181 710.585 146.406 718.609 153.616 723.609C160.828 728.607 170.127 729.962 178.574 727.242L302.129 687.44L377.571 788.943C382.728 795.88 391.087 800.002 400 800.002C408.912 800.002 417.272 795.88 422.428 788.943L497.87 687.44L621.423 727.242C629.866 729.962 639.17 728.608 646.381 723.609C653.591 718.609 657.816 710.585 657.714 702.082L656.227 577.647L780.7 540.548C789.208 538.011 795.899 531.698 798.654 523.609C801.408 515.52 799.884 506.66 794.563 499.836L716.712 400ZM400 655.343C252.454 655.343 132.417 540.796 132.417 399.998C132.417 259.201 252.454 144.655 400 144.655C547.545 144.655 667.582 259.201 667.582 399.998C667.582 540.796 547.545 655.343 400 655.343Z"/>
      </svg>
    </div>

    <div class="moon">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           x="0px" y="0px"
           width="100%" viewBox="0 0 500 500" enable-background="new 0 0 500 500" xml:space="preserve">
<path fill="#D6E1E9" opacity="1.000000" stroke="none"
      d="
M282.000000,501.000000
	C260.979095,501.000000 239.958206,501.000000 218.769775,500.635681
	C217.930069,499.854706 217.302582,499.191345 216.578735,499.060333
	C210.685181,497.993500 204.752853,497.134857 198.870972,496.010590
	C167.048416,489.927979 137.931137,477.248657 110.976471,459.491730
	C90.069542,445.718811 71.805740,429.034668 55.916161,409.615997
	C41.478283,391.971436 30.080938,372.552612 20.543528,351.977783
	C18.200146,346.922455 16.797020,341.431305 14.880223,335.909790
	C14.686337,335.342896 14.460882,335.122070 14.039359,334.815094
	C13.956256,334.613556 13.950966,334.177612 13.963444,333.867157
	C13.691474,333.043732 13.407027,332.530792 13.039477,331.815887
	C12.956374,331.613953 12.951176,331.177277 12.963461,330.866821
	C12.691309,330.043518 12.406870,329.530609 12.039310,328.816101
	C11.956188,328.614471 11.950968,328.178345 11.980599,327.832092
	C11.717188,326.664337 11.424146,325.842865 11.049493,324.813538
	C10.967883,324.605682 10.955758,324.159241 10.963699,323.851746
	C10.689299,323.035553 10.406958,322.526886 10.041853,321.814941
	C9.959089,321.611633 9.952535,321.172668 9.979796,320.740753
	C9.936984,319.154297 9.984943,317.978241 9.778736,316.848541
	C7.505645,304.395660 5.159832,291.955963 2.909846,279.498932
	C2.160985,275.352905 1.630382,271.167450 1.000001,267.000000
	C1.000000,257.312439 1.000000,247.624878 1.288838,237.176346
	C2.375194,229.187057 2.992037,221.933350 4.002948,214.735001
	C7.515569,189.722809 14.888717,165.825912 25.999136,143.189850
	C40.644951,113.350845 60.126274,87.145981 85.219734,65.086609
	C100.923477,51.281628 117.933632,39.541782 136.385300,29.883190
	C168.477722,13.084291 202.888184,4.374847 238.883484,2.152162
	C258.807709,0.921855 278.658203,2.813603 298.284058,6.614017
	C305.585663,8.027929 312.875000,9.505217 320.390564,10.956474
	C320.611145,10.959346 321.019379,11.126738 321.168030,11.354052
	C321.829773,11.715609 322.342896,11.849851 323.036926,12.339836
	C335.856384,17.301582 348.650665,21.531452 361.067902,26.672079
	C367.287903,29.247108 372.849182,33.435482 378.657043,36.978413
	C386.385345,41.692879 394.357513,46.083134 401.672974,51.375614
	C407.851807,55.845806 413.416504,61.223305 418.943878,66.521713
	C427.013000,74.256561 435.652313,81.615074 442.506805,90.344627
	C451.621857,101.953201 459.624603,114.495628 467.435638,127.053352
	C478.779327,145.290573 486.707642,165.158051 491.816467,185.938690
	C495.128815,199.411850 496.643982,213.325089 499.072540,227.021988
	C499.205475,227.771683 500.334564,228.344742 501.000000,229.000000
	C501.000000,243.354233 501.000000,257.708466 500.624847,272.229004
	C499.833679,273.406799 499.188507,274.385254 499.037231,275.434875
	C497.599670,285.406586 497.007111,295.568726 494.719086,305.335815
	C488.896820,330.189453 479.957306,353.901917 467.042511,376.070343
	C453.376709,399.527863 436.798492,420.459717 416.282471,438.368896
	C403.388519,449.624451 389.712128,459.567749 374.866119,468.075073
	C346.544861,484.304260 316.394073,494.941132 283.974945,499.075623
	C283.246674,499.168518 282.655243,500.334564 282.000000,501.000000
M263.119476,308.476013
	C241.091537,319.980377 229.258926,347.240967 239.922760,372.389984
	C248.891830,393.542114 271.048309,405.678436 292.149628,402.706360
	C316.123596,399.329681 333.260773,380.698700 335.316895,359.006500
	C336.736298,344.031677 333.101685,331.138947 323.372375,319.895386
	C309.326172,303.663055 285.296387,298.104919 263.119476,308.476013
M365.349365,150.020218
	C383.199249,146.148621 396.535889,130.735489 397.020172,113.739235
	C397.778046,87.140968 372.858276,68.998375 349.557281,73.807449
	C327.111603,78.439995 312.783600,99.389816 318.083801,120.694298
	C323.225311,141.360947 344.874542,155.079041 365.349365,150.020218
M77.016190,217.584839
	C61.628971,237.537781 70.600914,260.140442 84.693810,269.182465
	C97.701828,277.528442 114.009171,276.836090 126.118431,267.227234
	C137.279617,258.370697 141.765335,242.342987 137.009384,228.508774
	C128.793396,204.609848 97.052818,195.724380 77.016190,217.584839
M435.001465,284.378632
	C435.297943,292.849518 437.520569,300.736176 442.266693,307.814545
	C450.341858,319.857910 464.524353,319.920471 472.617249,307.982056
	C482.637939,293.199829 482.561035,271.515656 472.435638,256.750366
	C464.596466,245.318939 450.465363,245.254333 442.648102,256.630280
	C437.099396,264.704895 434.781738,273.713013 435.001465,284.378632
M147.465439,422.761749
	C145.979309,423.504272 144.446823,424.167175 143.014236,425.001648
	C128.290588,433.578094 128.151382,451.419189 142.900589,459.904480
	C155.923904,467.396790 168.893494,466.916626 181.330627,458.385712
	C193.006790,450.376740 192.943954,434.631195 181.278839,426.560303
	C170.983521,419.437164 159.922104,418.050171 147.465439,422.761749
M239.165192,18.050730
	C230.559601,17.708939 222.297089,18.976507 214.622757,23.155025
	C209.813614,25.773508 206.074875,29.406519 206.125153,35.121723
	C206.173294,40.594139 209.712692,44.246399 214.406494,46.709908
	C228.704269,54.213970 243.056656,54.011662 257.376526,46.810608
	C262.266968,44.351360 265.934906,40.579819 265.874603,34.863342
	C265.816925,29.393566 262.437164,25.322514 257.536652,23.396069
	C251.899826,21.180183 245.902847,19.880556 239.165192,18.050730
z"/>
        <path fill="#FCFDFD" opacity="1.000000" stroke="none"
              d="
M1.000000,212.468643
	C1.664503,211.345871 2.814092,210.763321 2.922698,210.027176
	C5.779029,190.666473 11.555036,172.156464 18.982382,154.114014
	C28.631170,130.675247 41.699364,109.228683 57.866642,89.810555
	C71.458443,73.485764 87.088623,59.205254 104.518448,46.919399
	C125.567657,32.082329 148.153564,20.203520 172.801346,12.858935
	C187.108719,8.595595 201.977966,6.220548 216.583237,2.946067
	C217.311508,2.782787 217.933380,2.144842 218.302399,1.363957
	C239.020889,1.000000 260.041779,1.000000 281.531342,1.000002
	C282.481384,1.660277 282.865356,2.788314 283.458496,2.911815
	C295.551544,5.429787 307.668518,7.832870 319.975342,10.606249
	C312.875000,9.505217 305.585663,8.027929 298.284058,6.614017
	C278.658203,2.813603 258.807709,0.921855 238.883484,2.152162
	C202.888184,4.374847 168.477722,13.084291 136.385300,29.883190
	C117.933632,39.541782 100.923477,51.281628 85.219734,65.086609
	C60.126274,87.145981 40.644951,113.350845 25.999136,143.189850
	C14.888717,165.825912 7.515569,189.722809 4.002948,214.735001
	C2.992037,221.933350 2.375194,229.187057 1.288838,236.707687
	C1.000000,228.979095 1.000000,220.958191 1.000000,212.468643
z"/>
        <path fill="#FAFBFC" opacity="1.000000" stroke="none"
              d="
M9.979795,320.740753
	C7.632543,311.744720 5.326485,302.313293 2.975147,292.893158
	C2.676809,291.697968 2.141274,290.561951 1.357813,289.699280
	C1.000000,282.645752 1.000000,275.291504 1.000001,267.468628
	C1.630382,271.167450 2.160985,275.352905 2.909846,279.498932
	C5.159832,291.955963 7.505645,304.395660 9.778736,316.848541
	C9.984943,317.978241 9.936984,319.154297 9.979795,320.740753
z"/>
        <path fill="#92B5CC" opacity="1.000000" stroke="none"
              d="
M263.453644,308.300812
	C285.296387,298.104919 309.326172,303.663055 323.372375,319.895386
	C333.101685,331.138947 336.736298,344.031677 335.316895,359.006500
	C333.260773,380.698700 316.123596,399.329681 292.149628,402.706360
	C271.048309,405.678436 248.891830,393.542114 239.922760,372.389984
	C229.258926,347.240967 241.091537,319.980377 263.453644,308.300812
z"/>
        <path fill="#92B5CC" opacity="1.000000" stroke="none"
              d="
M364.923523,150.062378
	C344.874542,155.079041 323.225311,141.360947 318.083801,120.694298
	C312.783600,99.389816 327.111603,78.439995 349.557281,73.807449
	C372.858276,68.998375 397.778046,87.140968 397.020172,113.739235
	C396.535889,130.735489 383.199249,146.148621 364.923523,150.062378
z"/>
        <path fill="#93B5CC" opacity="1.000000" stroke="none"
              d="
M77.256866,217.319458
	C97.052818,195.724380 128.793396,204.609848 137.009384,228.508774
	C141.765335,242.342987 137.279617,258.370697 126.118431,267.227234
	C114.009171,276.836090 97.701828,277.528442 84.693810,269.182465
	C70.600914,260.140442 61.628971,237.537781 77.256866,217.319458
z"/>
        <path fill="#93B5CC" opacity="1.000000" stroke="none"
              d="
M435.000732,283.908691
	C434.781738,273.713013 437.099396,264.704895 442.648102,256.630280
	C450.465363,245.254333 464.596466,245.318939 472.435638,256.750366
	C482.561035,271.515656 482.637939,293.199829 472.617249,307.982056
	C464.524353,319.920471 450.341858,319.857910 442.266693,307.814545
	C437.520569,300.736176 435.297943,292.849518 435.000732,283.908691
z"/>
        <path fill="#93B5CC" opacity="1.000000" stroke="none"
              d="
M147.815399,422.591095
	C159.922104,418.050171 170.983521,419.437164 181.278839,426.560303
	C192.943954,434.631195 193.006790,450.376740 181.330627,458.385712
	C168.893494,466.916626 155.923904,467.396790 142.900589,459.904480
	C128.151382,451.419189 128.290588,433.578094 143.014236,425.001648
	C144.446823,424.167175 145.979309,423.504272 147.815399,422.591095
z"/>
        <path fill="#93B5CC" opacity="1.000000" stroke="none"
              d="
M239.613739,18.116852
	C245.902847,19.880556 251.899826,21.180183 257.536652,23.396069
	C262.437164,25.322514 265.816925,29.393566 265.874603,34.863342
	C265.934906,40.579819 262.266968,44.351360 257.376526,46.810608
	C243.056656,54.011662 228.704269,54.213970 214.406494,46.709908
	C209.712692,44.246399 206.173294,40.594139 206.125153,35.121723
	C206.074875,29.406519 209.813614,25.773508 214.622757,23.155025
	C222.297089,18.976507 230.559601,17.708939 239.613739,18.116852
z"/>
        <path fill="#FAFBFC" opacity="1.000000" stroke="none"
              d="
M11.980601,327.832092
	C11.634923,327.363525 11.318875,326.548676 11.066965,325.377625
	C11.424146,325.842865 11.717188,326.664337 11.980601,327.832092
z"/>
        <path fill="#FCFDFD" opacity="1.000000" stroke="none"
              d="
M321.168030,11.354050
	C321.628784,11.198651 322.238220,11.270568 322.851807,11.663289
	C322.342896,11.849851 321.829773,11.715609 321.168030,11.354050
z"/>
        <path fill="#FAFBFC" opacity="1.000000" stroke="none"
              d="
M10.963699,323.851746
	C10.638743,323.661133 10.321728,323.162994 10.064665,322.341553
	C10.406958,322.526886 10.689299,323.035553 10.963699,323.851746
z"/>
        <path fill="#FAFBFC" opacity="1.000000" stroke="none"
              d="
M12.963457,330.866821
	C12.635700,330.673462 12.320233,330.169617 12.063600,329.341736
	C12.406870,329.530609 12.691309,330.043518 12.963457,330.866821
z"/>
        <path fill="#FAFBFC" opacity="1.000000" stroke="none"
              d="
M13.963446,333.867157
	C13.635574,333.673676 13.320180,333.169739 13.063683,332.341797
	C13.407027,332.530792 13.691474,333.043732 13.963446,333.867157
z"/>
        <path fill="#FAFBFC" opacity="1.000000" stroke="none"
              d="
M14.880224,335.909790
	C14.663485,335.947510 14.365345,335.754486 14.094833,335.289062
	C14.460882,335.122070 14.686337,335.342896 14.880224,335.909790
z"/>
</svg>
    </div>

    <div class="clouds">
      <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"
           aria-hidden="true" role="img" class="iconify iconify--twemoji left-cloud"
           preserveAspectRatio="xMidYMid meet">
        <path fill="#E1E8ED"
              d="M27 8a6.98 6.98 0 0 0-2.015.298c.005-.1.015-.197.015-.298a5.998 5.998 0 0 0-11.785-1.573A5.974 5.974 0 0 0 11 6a6 6 0 1 0 0 12a5.998 5.998 0 0 0 5.785-4.428A5.975 5.975 0 0 0 19 14c.375 0 .74-.039 1.096-.104c-.058.36-.096.727-.096 1.104c0 3.865 3.135 7 7 7s7-3.135 7-7a7 7 0 0 0-7-7z"></path>
        <path fill="#b2c6d7"
              d="M31 22c-.467 0-.91.085-1.339.204c.216-.526.339-1.1.339-1.704a4.5 4.5 0 0 0-4.5-4.5a4.459 4.459 0 0 0-2.701.921A6.497 6.497 0 0 0 16.5 12a6.497 6.497 0 0 0-6.131 4.357A8 8 0 1 0 8 32h23c2.762 0 5-2.238 5-5s-2.238-5-5-5z"></path>
      </svg>
      <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"
           aria-hidden="true" role="img" class="iconify iconify--twemoji right-cloud"
           preserveAspectRatio="xMidYMid meet">
        <path fill="#CCD6DD"
              d="M27 8a6.98 6.98 0 0 0-2.015.298c.005-.1.015-.197.015-.298a5.998 5.998 0 0 0-11.785-1.573A5.974 5.974 0 0 0 11 6a6 6 0 1 0 0 12a5.998 5.998 0 0 0 5.785-4.428A5.975 5.975 0 0 0 19 14c.375 0 .74-.039 1.096-.104c-.058.36-.096.727-.096 1.104c0 3.865 3.135 7 7 7s7-3.135 7-7a7 7 0 0 0-7-7z"></path>
        <path fill="#8b8f94"
              d="M31 22c-.467 0-.91.085-1.339.204c.216-.526.339-1.1.339-1.704a4.5 4.5 0 0 0-4.5-4.5a4.459 4.459 0 0 0-2.701.921A6.497 6.497 0 0 0 16.5 12a6.497 6.497 0 0 0-6.131 4.357A8 8 0 1 0 8 32h23c2.762 0 5-2.238 5-5s-2.238-5-5-5z"></path>
      </svg>
      <div class="cloud-text">
        <h1 id="name">Hi, ich bin Manu!</h1>
      </div>
    </div>

    <div class="stars">
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
    </div>


    <div class="inner-content">
    </div>

  </section>
  <section class="full-content" id="sunScene"></section>
  <section class="full-content" id="rainScene"></section>
  <section class="full-content" id="earthScene">
    <div class="rocket">
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           width="100%" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
<path fill="#41596B" opacity="1.000000" stroke="none"
      d="
M98.974930,261.849182
	C69.853394,264.399536 43.481613,274.039459 20.823631,292.775818
	C14.079606,298.352570 8.003902,304.737518 1.310992,310.876099
	C1.000000,306.975464 1.000000,302.950897 1.000001,298.463196
	C1.656714,297.288696 2.745095,296.663269 2.906229,295.853363
	C5.953872,280.534973 11.102831,265.906158 18.077723,252.002548
	C25.426451,237.353760 34.739590,224.039795 45.633472,211.654114
	C64.841156,189.816162 88.395546,174.392563 115.002640,163.644119
	C125.897064,159.243118 137.752411,157.220810 149.484421,154.454346
	C148.764679,156.684860 147.867416,158.668121 146.677338,160.456543
	C125.756859,191.895294 109.902954,225.712097 98.974930,261.849182
z"/>
        <path fill="#41596B" opacity="1.000000" stroke="none"
              d="
M203.460648,513.000000
	C209.304901,506.195129 216.034454,499.731323 221.829147,492.516693
	C235.248291,475.809326 244.271072,456.826111 248.910995,435.886322
	C250.367950,429.311157 251.023193,422.558380 252.456421,415.358093
	C287.296387,404.673920 319.453186,389.630249 349.526428,370.055206
	C352.540802,368.093170 355.934723,366.714142 359.152466,365.064484
	C355.674774,377.580444 352.928040,390.359131 348.546356,402.550201
	C342.129486,420.403717 331.932007,436.435577 319.567719,450.661499
	C305.750732,466.558929 289.955505,480.522461 271.349060,490.863312
	C253.955536,500.530090 235.661224,507.803070 215.962204,511.242828
	C215.572693,511.310822 215.269333,511.872223 214.962891,512.601685
	C211.307098,513.000000 207.614182,513.000000 203.460648,513.000000
z"/>
        <path fill="#344A5E" opacity="1.000000" stroke="none"
              d="
M391.583252,8.175516
	C404.029510,6.428119 416.477692,4.694149 428.916962,2.898463
	C429.628815,2.795699 430.267944,2.189038 430.970459,1.408448
	C458.262390,1.000000 485.524780,1.000000 513.000000,1.000000
	C513.000000,29.687210 513.000000,58.374947 513.000000,87.531342
	C512.339600,89.084488 511.271240,90.102715 511.082611,91.263840
	C509.429901,101.437943 507.934204,111.637566 505.797607,121.980896
	C504.701324,121.695068 504.171143,121.286285 503.701599,120.816849
	C467.168701,84.294441 430.638611,47.769234 394.123322,11.229236
	C393.191315,10.296571 392.426208,9.197165 391.583252,8.175516
z"/>
        <path fill="#F2705B" opacity="1.000000" stroke="none"
              d="
M391.195099,8.119661
	C392.426208,9.197165 393.191315,10.296571 394.123322,11.229236
	C430.638611,47.769234 467.168701,84.294441 503.701599,120.816849
	C504.171143,121.286285 504.701324,121.695068 505.513184,122.298416
	C504.870697,127.878204 504.275452,133.383835 502.906952,138.690063
	C494.882446,169.805038 483.566864,199.681915 467.978333,227.812332
	C456.037262,249.360687 442.542816,269.931580 426.068207,288.339386
	C415.813141,299.797821 405.525360,311.307922 394.351135,321.838623
	C384.397064,331.219482 373.203827,339.285400 361.933289,348.189789
	C360.877197,348.697968 360.419281,348.912048 360.017029,349.203156
	C328.085022,372.312225 293.382233,389.951233 255.859497,402.046539
	C254.471069,402.494080 253.234634,403.413147 251.927353,404.112488
	C241.562576,406.747559 231.197815,409.382629 220.124939,411.860809
	C218.671021,409.393890 217.897247,407.092468 217.184372,404.772339
	C214.522324,396.108551 211.880219,387.438690 209.147797,378.419647
	C203.284851,368.163910 197.504730,358.259613 191.856903,348.149841
	C217.403931,323.871796 238.580505,296.866364 251.771255,262.369720
	C248.642960,263.516327 246.906952,263.950195 245.361725,264.751831
	C235.346588,269.947479 225.073441,274.730255 215.449753,280.577972
	C196.870499,291.867493 181.012268,306.575592 165.547073,321.790649
	C155.276855,315.976135 145.263489,310.360687 134.950012,304.548676
	C125.455658,301.678162 116.248940,299.045715 107.077820,296.294617
	C105.274750,295.753754 103.609184,294.754517 101.880203,293.966675
	C104.574577,283.605194 107.268951,273.243713 110.226364,262.246460
	C111.018105,260.400391 111.653511,259.224792 112.059860,257.974731
	C121.518204,228.878540 134.199951,201.279007 150.509369,175.380264
	C155.464767,167.511276 160.665131,159.796555 165.753296,152.011169
	C172.091644,143.918594 178.074966,135.513062 184.860657,127.814781
	C192.855087,118.745201 201.249069,109.978889 209.992386,101.628227
	C229.903824,82.610947 251.810364,66.123138 275.546082,52.162746
	C299.824646,37.883076 325.210419,25.937460 352.299744,17.997787
	C365.013824,14.271395 377.963837,11.350008 391.195099,8.119661
M414.848724,129.705826
	C395.859589,98.162735 361.019318,82.630180 327.045471,90.561188
	C290.936859,98.990555 266.630371,127.411026 263.561584,164.790054
	C259.415497,215.290176 306.923950,258.368774 357.170258,249.670334
	C412.928986,240.017624 442.002960,180.218781 414.848724,129.705826
z"/>
        <path fill="#354B5E" opacity="1.000000" stroke="none"
              d="
M251.951172,404.563538
	C253.234634,403.413147 254.471069,402.494080 255.859497,402.046539
	C293.382233,389.951233 328.085022,372.312225 360.017029,349.203156
	C360.419281,348.912048 360.877197,348.697968 361.622803,348.371857
	C361.276215,353.271942 360.615967,358.248077 359.752136,363.812988
	C359.364624,364.632080 359.175964,364.858459 358.982574,365.080902
	C358.982574,365.080902 359.059357,364.992035 359.105896,365.028259
	C355.934723,366.714142 352.540802,368.093170 349.526428,370.055206
	C319.453186,389.630249 287.296387,404.673920 252.505203,414.925781
	C252.086975,411.686340 252.030975,408.350494 251.951172,404.563538
z"/>
        <path fill="#41596B" opacity="1.000000" stroke="none"
              d="
M359.205750,364.988525
	C359.175964,364.858459 359.364624,364.632080 359.697052,364.238159
	C359.877777,364.435120 359.738892,364.708984 359.205750,364.988525
z"/>
        <path fill="#364B5E" opacity="1.000000" stroke="none"
              d="
M165.344330,151.987793
	C160.665131,159.796555 155.464767,167.511276 150.509369,175.380264
	C134.199951,201.279007 121.518204,228.878540 112.059860,257.974731
	C111.653511,259.224792 111.018105,260.400391 110.220139,261.855408
	C106.596214,262.052277 103.241539,262.004395 99.430901,261.902832
	C109.902954,225.712097 125.756859,191.895294 146.677338,160.456543
	C147.867416,158.668121 148.764679,156.684860 149.886383,154.458099
	C154.961487,153.404068 159.948425,152.684235 165.344330,151.987793
z"/>
        <path fill="#F6B44B" opacity="1.000000" stroke="none"
              d="
M209.230621,378.771088
	C211.880219,387.438690 214.522324,396.108551 217.184372,404.772339
	C217.897247,407.092468 218.671021,409.393890 219.721344,411.915466
	C215.563461,419.039734 211.592117,426.337006 206.540283,432.788116
	C194.652145,447.969025 179.405701,459.186951 162.060471,467.358246
	C150.313232,472.892334 137.977036,476.934082 124.880867,477.995361
	C118.164444,478.539673 111.437958,478.959686 103.787704,479.501617
	C104.232361,472.268707 104.630447,465.793304 105.074158,458.575745
	C84.409737,468.617981 63.469772,475.146973 40.195724,473.877502
	C38.705162,450.987152 45.238979,429.985718 55.394936,408.866516
	C47.982273,409.320068 41.212322,409.734344 34.216290,410.162415
	C35.670704,358.978760 56.150616,319.413605 101.520515,293.987671
	C103.609184,294.754517 105.274750,295.753754 107.077820,296.294617
	C116.248940,299.045715 125.455658,301.678162 134.563766,304.673767
	C130.690231,306.264618 126.789841,307.271118 123.133217,308.844238
	C91.714172,322.361359 70.886436,345.397552 61.219944,378.306213
	C59.770027,383.242310 60.911282,384.408905 66.027580,383.650421
	C72.943542,382.625122 79.834915,381.434723 86.747665,380.386597
	C90.281288,379.850861 93.838509,379.470825 98.706902,378.852539
	C87.449341,402.661896 70.840424,422.488220 65.284828,448.686218
	C91.622803,443.257477 111.292831,426.315857 134.948593,415.494141
	C134.692093,418.314423 134.627197,419.921234 134.388748,421.501862
	C133.047806,430.390381 131.611633,439.264862 130.317871,448.160095
	C129.611710,453.015472 130.742065,454.042938 135.396805,452.850616
	C137.971329,452.191132 140.510315,451.336517 142.992126,450.379974
	C176.786011,437.355103 199.008377,413.608673 209.230621,378.771088
z"/>
        <path fill="#FFD05C" opacity="1.000000" stroke="none"
              d="
M209.147797,378.419647
	C199.008377,413.608673 176.786011,437.355103 142.992126,450.379974
	C140.510315,451.336517 137.971329,452.191132 135.396805,452.850616
	C130.742065,454.042938 129.611710,453.015472 130.317871,448.160095
	C131.611633,439.264862 133.047806,430.390381 134.388748,421.501862
	C134.627197,419.921234 134.692093,418.314423 134.948593,415.494141
	C111.292831,426.315857 91.622803,443.257477 65.284828,448.686218
	C70.840424,422.488220 87.449341,402.661896 98.706902,378.852539
	C93.838509,379.470825 90.281288,379.850861 86.747665,380.386597
	C79.834915,381.434723 72.943542,382.625122 66.027580,383.650421
	C60.911282,384.408905 59.770027,383.242310 61.219944,378.306213
	C70.886436,345.397552 91.714172,322.361359 123.133217,308.844238
	C126.789841,307.271118 130.690231,306.264618 134.863861,304.870300
	C145.263489,310.360687 155.276855,315.976135 165.413025,322.087524
	C148.929626,340.655365 134.598633,360.287384 124.537766,382.802399
	C123.073952,386.078278 122.182915,389.610077 120.868317,393.038269
	C120.782433,393.017059 120.855072,392.981049 121.302734,393.070557
	C124.926628,391.950439 128.243378,390.856171 131.413498,389.434875
	C153.953812,379.329071 173.509720,364.822205 191.724625,348.355316
	C197.504730,358.259613 203.284851,368.163910 209.147797,378.419647
z"/>
        <path fill="#FEFEFE" opacity="1.000000" stroke="none"
              d="
M415.011658,130.036880
	C442.002960,180.218781 412.928986,240.017624 357.170258,249.670334
	C306.923950,258.368774 259.415497,215.290176 263.561584,164.790054
	C266.630371,127.411026 290.936859,98.990555 327.045471,90.561188
	C361.019318,82.630180 395.859589,98.162735 415.011658,130.036880
M353.666901,116.608238
	C323.876190,111.773514 296.172699,131.323334 291.274170,160.637604
	C286.417725,189.699860 305.788666,217.337723 334.726410,222.633972
	C364.090057,228.008163 391.894867,208.669937 397.424072,179.027725
	C402.785461,150.285110 383.621765,122.477921 353.666901,116.608238
z"/>
        <path fill="#435A6B" opacity="1.000000" stroke="none"
              d="
M121.026840,393.023468
	C122.182915,389.610077 123.073952,386.078278 124.537766,382.802399
	C134.598633,360.287384 148.929626,340.655365 165.669891,322.286621
	C181.012268,306.575592 196.870499,291.867493 215.449753,280.577972
	C225.073441,274.730255 235.346588,269.947479 245.361725,264.751831
	C246.906952,263.950195 248.642960,263.516327 251.771255,262.369720
	C238.580505,296.866364 217.403931,323.871796 191.856903,348.149841
	C173.509720,364.822205 153.953812,379.329071 131.413498,389.434875
	C128.243378,390.856171 124.926628,391.950439 121.327942,393.089600
	C120.978134,392.983185 121.026840,393.023468 121.026840,393.023468
z"/>
        <path fill="#435A6B" opacity="1.000000" stroke="none"
              d="
M120.952927,392.964111
	C120.855072,392.981049 120.782433,393.017059 120.868317,393.038269
	C121.026840,393.023468 120.978134,392.983185 120.952927,392.964111
z"/>
        <path fill="#89D6F7" opacity="1.000000" stroke="none"
              d="
M354.092682,116.689217
	C383.621765,122.477921 402.785461,150.285110 397.424072,179.027725
	C391.894867,208.669937 364.090057,228.008163 334.726410,222.633972
	C305.788666,217.337723 286.417725,189.699860 291.274170,160.637604
	C296.172699,131.323334 323.876190,111.773514 354.092682,116.689217
z"/>
</svg>
    </div>
    <div class="earth">
      <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           width="2177.339px" height="543.795px" viewBox="0 29.611 2177.339 543.795"
           enable-background="new 0 29.611 2177.339 543.795"
           xml:space="preserve">
<g display="none">
	<path display="inline" fill="#FFFFFF" d="M1501.429,201.559c0,0,33.012-0.021,33.012-19.138c0-19.116-23.678-20.759-23.678-20.759
		s2.271-16.218-13.622-27.894c-15.894-11.677-35.354-0.325-35.354-0.325s-3.819-32.229-37.553-34.177
		c-33.731-1.945-44.256,29.728-44.256,29.728s-22.817-7.284-37.09,2.771c-14.271,10.055-12.142,32.168-12.142,32.168
		s-21.731,1.945-21.731,19.786c0,17.839,25.941,17.839,25.941,17.839H1501.429z"/>
  <path display="inline" fill="#FFFFFF" d="M771.438,99.181c0,0,28.688-0.734,28.688-17.024s-17.917-18.75-17.917-18.75
		s2.917-20.25-11.833-26.75s-30.5,11.5-30.5,11.5s-9.25-16.75-29.75-14s-23,18.5-23,18.5s-20.5-10.854-31.75,0.646
		s-1.858,25.768-1.858,25.768s-13.142,1.101-13.142,11.482c0,10.381,16.21,10.523,16.21,10.523L771.438,99.181z"/>
  <path display="inline" fill="#FFFFFF" d="M656.777,519.377c0,0,61.374,0.215,61.374-55.729s-61.374-51.827-61.374-51.827
		s2.728-82.513-55.918-90.015c-58.646-7.5-73.648,68.192-73.648,68.192s-8.184-27.276-30.687-27.958
		c-22.504-0.683-42.279,22.503-42.279,22.503s-19.095-20.458-54.556-19.774c-35.461,0.681-51.825,12.956-51.825,12.956
		s-23.869-47.279-74.785-34.098c-50.917,13.185-39.779,71.604-39.779,71.604s-29.322-12.481-48.417,3.646
		c-19.095,16.13-3.41,52.952-3.41,52.952s-41.598-2.954-41.598,22.051c0,25.004,41.598,25.496,41.598,25.496L656.777,519.377
		L656.777,519.377z"/>
  <path display="inline" fill="#FFFFFF" d="M1479.976,540.087c0,0-106.473,1.304-106.473-68.646
		c0-69.951,93.976-63.874,93.976-63.874s-9.138-64.875,36.223-81.675c45.357-16.801,85.397,57.118,85.397,57.118
		s35.56-44.52,83.437-43.68c47.88,0.84,77.841,39.199,77.841,39.199s52.883-28.237,83.857-2.683
		c30.977,25.553,18.898,63.439,18.898,63.439s73.078-15.118,73.078,42.841s-75.599,57.959-75.599,57.959L1479.976,540.087
		L1479.976,540.087z"/>
  <path display="inline" fill="#FFFFFF" d="M817.861,399.056c0,0-54.298-0.092-54.298-49.306s54.298-45.853,54.298-45.853
		s-2.414-73.001,49.473-79.638c51.885-6.637,65.157,60.332,65.157,60.332s7.24-24.134,27.149-24.735
		c19.909-0.604,37.405,19.909,37.405,19.909s16.894-18.101,48.266-17.496c31.373,0.603,45.853,11.463,45.853,11.463
		s21.116-41.829,66.163-30.166c45.048,11.664,35.193,63.349,35.193,63.349s25.941-11.042,42.835,3.228
		c16.895,14.27,3.018,46.848,3.018,46.848s36.803-2.613,36.803,19.508s-36.803,22.559-36.803,22.559L817.861,399.056
		L817.861,399.056z"/>
</g>
        <path fill="#1F71B8" d="M918.083,259.482"/>
        <path fill="#2B9E41" d="M2041.09,595.884"/>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2183.426" y1="470.4167" x2="2031.1282"
                        y2="470.4167">
	<stop offset="0" style="stop-color:#088432"/>
          <stop offset="1" style="stop-color:#00983A;stop-opacity:0.5"/>
</linearGradient>
        <path fill="url(#SVGID_1_)" d="M2031.832,419.819c5.908,14.772,8.353,48.245,9.211,81.83c1.16,45.345,102.791,39.554,138.124-12.446
	S2017.948,385.11,2031.832,419.819z"/>
        <path fill="#285DA9" d="M1363.921,573.178"/>
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1088.6702" y1="73.406" x2="1088.6702"
                        y2="573.4065">
	<stop offset="0" style="stop-color:#2D81CD;stop-opacity:0.3"/>
          <stop offset="1" style="stop-color:#000000;stop-opacity:0"/>
</linearGradient>
        <path fill="url(#SVGID_2_)" d="M1136.401,73.414c-14.706-0.005-30.523-0.008-47.614-0.008c-15.292,0-30.288,0.005-45.021,0.015
	C665.977,74.799,310.854,92.542,0,122.818v450.588h170.533h737.031h456.121c0.113-0.115,0.235-0.228,0.351-0.342h636.337h176.966
	V428.881v-126.64V122.3C1867.07,92.273,1512.966,74.714,1136.401,73.414z"/>
        <path fill="#285DA9" d="M1139.591,73.425c-16.594-0.013-33.515-0.019-50.805-0.019c-15.292,0-30.288,0.005-45.021,0.015
	C665.977,74.799,310.854,92.542,0,122.818v450.588h170.533h737.031c0,0,456.356-0.228,456.472-0.342h636.337h176.966V428.881
	v-126.64V122.3C1867.947,92.358,1514.966,74.813,1139.591,73.425z"/>
        <path fill="#00983A" d="M1959.991,103.856c-1.742,7.079-2.519,13.42-1.958,18.467c2.467,22.213-24.179,12.171-49.533-0.752
	s-25.719,65.479-16.203,85.611s81.646-31.482,102.758-40.897s32.782,37.827,55.445,30.585c25.339-8.097,41.549-60.623,56.205-45.049
	c27.77,29.508,50.461,58.05,19.128,88.716s-170.339-13.091-186.836-8.545s-91.001,80.324-53.974,138.172
	c37.021,57.857,132.924,14.947,146.809,49.656c10.678,26.695,10.042,114.454,9.178,153.587h136.329V326.113
	c-32.74-24.245-62.779-48.091-46.172-52.243c12.95-3.237,29.79,3.057,46.172,13.263V148.825V122.3
	C2107.055,115.498,2034.526,109.335,1959.991,103.856z"/>
        <path fill="#2B9E41" d="M2110.821,116.113c-13.945,12.836-27.56,25.813-36.32,35.09c-22.666,24-58.666-18-83.333,1
	s-104.948-9.459-104.596,17.992c0.195,15.216,2.332,29.807,5.726,36.987c9.516,20.132,81.646-31.482,102.758-40.897
	s32.782,37.827,55.445,30.585c25.339-8.097,41.549-60.623,56.205-45.049c27.77,29.508,50.461,58.05,19.128,88.716
	s-170.339-13.091-186.836-8.545s-91.001,80.324-53.974,138.172c16.744,26.167,101.817-29.491,164.977-7.96
	c88,30,140.9-25.983,123.758-38.748c-31.295-23.305-58.591-45.585-42.591-49.585c12.95-3.237,29.79,3.057,46.172,13.263V148.825
	V122.3C2155.382,120.175,2133.209,118.112,2110.821,116.113z"/>
        <path fill="#1F71B8" d="M1202.766,384.437c-61.422-20.406-98.428-61.272-93.696-85.293c4.73-24.02,63.578-99.247,49.43-134.317
	c-10.931-27.094,1.112-72.179,7.888-91.278c-10.387-0.06-20.796-0.104-31.219-0.139c-24.567-0.012-63.245,0.002-116.681,0.127
	c-123.455,0.69-244.422,3.13-362.146,7.182c9.23,8.557,18.979,20.175,27.949,35.919c29.554,51.875,27.083,97.885,41.271,142.028
	c14.188,44.143,126.451,28.26,160.124,68.118c33.673,39.856-95.758,96.574-156.983,122.168
	c-42.238,17.656-27.438,91.104-17.337,125.777l455.144-1.933c23.667-24.098,64.591-41.264,96.755-71.281
	C1307.753,459.992,1264.186,404.844,1202.766,384.437z"/>
        <path fill="#42A54A" d="M1077.766,227.634c46.309,72.471,30.107-65.462,45.816-99.21c-0.024,0.017-56,37.348-118.058,20.676
	c-62.072-16.676-87.45,108.748-87.45,108.748S1031.458,155.164,1077.766,227.634z"/>
        <path fill="#00983A" d="M846.483,509.225c-9.21-49.459,147.313-150.023,147.313-150.023l-30.473-1.928
	c0,0-229.103,109.166-240.142,154.123c-11.036,44.956,98.415,62.864,98.415,62.864l96.707-0.41l12.45-0.054
	C930.753,573.798,855.692,558.682,846.483,509.225z"/>
        <path fill="#2B9E41" d="M1228.016,416.37c-15.345-31.798-199.755-54.988-199.755-54.988l-34.466-2.18
	c0,0-156.522,100.564-147.313,150.023c9.209,49.457,84.271,64.572,84.271,64.572l203.268-0.863c0,0,5.721-32.558,30.112-57.284
	C1188.525,490.921,1249.639,461.188,1228.016,416.37z"/>
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="844.2659" y1="76.1404" x2="844.2659"
                        y2="359.2043">
	<stop offset="0" style="stop-color:#088432"/>
          <stop offset="1" style="stop-color:#00983A;stop-opacity:0.5"/>
</linearGradient>
        <path fill="url(#SVGID_3_)" d="M938.512,303.053c-14.441-17.934-20.437-45.205-20.437-45.205s-62.99-55.258-78.211-107.028
	c-6.853-23.307-13.112-52.111-17.925-74.68c-42.776,0.896-85.188,2.004-127.202,3.317c7.379,33.104,33.877,126.467,103.854,163.34
	c85.929,45.278,164.729,114.445,164.729,114.445l30.474,1.961C993.794,359.204,952.953,320.989,938.512,303.053z"/>
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="821.9417" y1="165.6042" x2="1142.5647"
                        y2="165.6042">
	<stop offset="0" style="stop-color:#2B9E41"/>
          <stop offset="1" style="stop-color:#00983A;stop-opacity:0.5"/>
</linearGradient>
        <path fill="url(#SVGID_4_)" d="M1117.253,73.362c-32.022,0.034-64.105,0.084-96.293,0.162
	c-67.113,0.363-133.492,1.242-199.019,2.616c4.813,22.57,11.072,51.374,17.925,74.68c15.221,51.77,78.211,107.028,78.211,107.028
	s25.376-125.424,87.448-108.748c62.057,16.672,118.032-20.66,118.059-20.676c0.864-3.042,1.834-5.763,2.935-8.083
	c8.443-17.795,13.282-35.213,16.046-46.905C1134.139,73.403,1125.701,73.379,1117.253,73.362z"/>
        <path fill="#1F71B8" d="M1427.727,77.723c66.058,17.743,100.029,87.898,124.95,85.896c27.406-2.201,165.187-36.787,143.186-75.854
	C1608.481,83.495,1518.99,80.127,1427.727,77.723z"/>
        <path fill="#1F71B8" d="M136.733,455.203c-48-38-152-170-66-215.26c46.299-24.366-9.421-62.706-70.733-92.913v1.794v424.581h100.402
	C141.763,534.374,166.431,478.715,136.733,455.203z"/>
</svg>
    </div>
  </section>
  <section class="full-content" id="rocketScene"></section>
  <section class="full-content" id="startScene"></section>
  <section class="full-content" id="spaceScene"></section>
</template>

<script>
import '@/assets/css/styles.css'
import ScrollMagic from 'scrollmagic';

export default {
  name: "homePage",
}

document.onreadystatechange = () => {
  const sun = document.querySelector('.sun');
  // eslint-disable-next-line no-unused-vars
  const moon = document.querySelector('.moon');
  const earth = document.querySelector('.earth');
  const rocket = document.querySelector('.rocket');
  const rightCloud = document.querySelector('.right-cloud');
  const leftCloud = document.querySelector('.left-cloud');
  const controller = new ScrollMagic.Controller();

  const cloudScene = new ScrollMagic.Scene({
    duration: "200vh",
  }).setPin('#cloudScene');
  controller.addScene(cloudScene);

  const sunScene = new ScrollMagic.Scene({
    duration: "200vh",
    triggerElement: '#sunScene',
  }).setPin("#sunScene");
  controller.addScene(sunScene);

  const earthScene = new ScrollMagic.Scene({
    duration: "200vh",
    triggerElement: '#earthScene',
  }).setPin("#earthScene");
  controller.addScene(earthScene);

  const rocketScene = new ScrollMagic.Scene({
    duration: "200vh",
    triggerElement: '#rocketScene',
  }).setPin("#rocketScene");
  controller.addScene(rocketScene);

  const startScene = new ScrollMagic.Scene({
    duration: "200vh",
    triggerElement: '#startScene',
  }).setPin("#startScene");
  controller.addScene(startScene);

  const spaceScene = new ScrollMagic.Scene({
    duration: "200vh",
    triggerElement: '#spaceScene',
  }).setPin("#spaceScene");
  controller.addScene(spaceScene);

  let lastCloudMove = 0;
  let lastCloudYMove = 0;

  cloudScene.on('progress', (e) => {
    let move = e.progress * 500;
    leftCloud.style.transform = "translate(calc(-15% - " + move + "px), 0%)";
    rightCloud.style.transform = "translate(calc(15% + " + move + "px), 0%) scaleX(-1)";
    lastCloudMove = move;
    if (e.progress > 0.75) {
      document.querySelector('body').style.backgroundColor = "#df6c98";
    } else {
      document.querySelector('body').style.backgroundColor = "#5550ec";
    }
    moon.style.transform = "translate(-" + move / 1.25 + "px, " + move / 1.25 + "px)";
  });

  let height = window.innerHeight;
  let width = window.innerWidth;
  let xPos = -width / 4;
  let yPos = -height / 4;

  sunScene.on('progress', (e) => {
    leftCloud.style.transform = "translate(calc(-15% - " + lastCloudMove + "px), calc(0% - " + lastCloudYMove + "%))";
    rightCloud.style.transform = "translate(calc(15% + " + lastCloudMove + "px), calc(0% - " + -lastCloudYMove + "%)) scaleX(-1)";
    let move = e.progress * 1.9;
    sun.style.transform = "translate(calc(-50% + " + move * xPos + "px), calc(-50% + " + move * yPos + "px))";
  });

  earthScene.on('progress', (e) => {
    leftCloud.style.transform = "translate(calc(-15% - " + lastCloudMove + "px), calc(0% - " + lastCloudYMove + "%))";
    rightCloud.style.transform = "translate(calc(15% + " + lastCloudMove + "px), calc(0% - " + lastCloudYMove + "%)) scaleX(-1)";
    let moveEarth = -50 + (e.progress * 20);
    let moveRocket = -50 + (e.progress * 65);
    earth.style.bottom = moveEarth + "%";
    rocket.style.bottom = moveRocket + "%";
  });

  rocketScene.on('progress', (e) => {
    leftCloud.style.transform = "translate(calc(-15% - " + lastCloudMove + "px), calc(0% - " + lastCloudYMove + "%))";
    rightCloud.style.transform = "translate(calc(15% + " + lastCloudMove + "px), calc(0% - " + lastCloudYMove + "%)) scaleX(-1)";
    let moveRocket = 15 + (e.progress * 65);
    rocket.style.bottom = moveRocket + "%";
  });

  startScene.on('progress', (e) => {
    let move = e.progress * 100;
    lastCloudYMove = move;
    leftCloud.style.transform = "translate(calc(-15% - " + lastCloudMove + "px), calc(50% - " + -move + "%))";
    rightCloud.style.transform = "translate(calc(15% + " + lastCloudMove + "px), calc(50% - " + -move + "%)) scaleX(-1)";
    earth.style.bottom = -move / 2.25 + "%";
  });

  spaceScene.on('progress', (e) => {
    let move = -e.progress * 500;
    moon.style.transform = "translate(-" + ((lastCloudMove) - (move)) + "px, " + ((lastCloudMove) - (move)) + "px)";
    earth.style.bottom = move / 2.25 + "%";
    document.querySelector('body').style.backgroundColor = "#070727";
  });

}
</script>

<style scoped>
</style>