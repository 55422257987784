import {createApp} from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";

// 1. Define route components.
// These can be imported from other files
// import homePage from "@/components/homePage";
//import contentPage from "@/components/contentPage";
import homePage from "@/components/homePage.vue";

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    {path: '/', component: homePage},
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {top: 0}
    },
    routes
})

createApp(App).use(router).mount('#app')
